import { useContext, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  useTheme,
  IconButton,
} from '@mui/material';
import { tokens, ColorModeContext } from '../../theme';
import ComputerIcon from '@mui/icons-material/Computer'; // Icon for RDP
import CloudIcon from '@mui/icons-material/Cloud'; // Icon for VPS
import SecurityIcon from '@mui/icons-material/Security'; // Icon for Proxy
import LightModeIcon from '@mui/icons-material/LightMode';
import FavoriteIcon from '@mui/icons-material/Favorite'; // Heart Icon
import userLogo from '../../assets/user.png';
import vpsAsset from '../../assets/vps.png';
import server1 from '../../assets/server.png'; // Vector image 1
import server2 from '../../assets/server2.png'; // Vector image 2
import { Link } from 'react-router-dom';

const GlowingCard = ({ title, description, icon, size }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [hovered, setHovered] = useState(false);

  return (
    <Box
      sx={{
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: `0px 0px 20px 5px ${colors.greenAccent[500]}`,
        },
        borderRadius: '8px',
        backgroundColor: colors.primary[400],
        minHeight: size === 'large' ? '200px' : '150px',
        padding: size === 'large' ? '20px' : '15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {icon}
      <Typography variant="h6" color={colors.grey[100]} fontWeight="600" mt={1}>
        {title}
      </Typography>
      <Typography variant="body2" color={colors.grey[300]} mt="8px">
        {description}
      </Typography>
    </Box>
  );
};

const HomePage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <img
          src={userLogo}
          alt="User Logo"
          style={{ width: '100px', marginBottom: '20px' }}
        />
        <Box>
          <Button
            sx={{
              backgroundColor:colors.greenAccent[700],
              color: colors.grey[100],
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 20px',
              marginRight: '10px',
            }}
            href="/login"
          >
            Login
          </Button>
          <IconButton
            sx={{ color: colors.grey[100] }}
            onClick={colorMode.toggleColorMode}
          >
            <LightModeIcon />
          </IconButton>
        </Box>
      </Box>

      {/* WELCOME SECTION */}
      <Box display="flex" justifyContent="center" alignItems="center" mt="20px">
        <Box textAlign="center" flex="1">
          <img
            src={vpsAsset}
            alt="vps Logo"
            style={{ width: '300px', marginBottom: '20px' }} // Adjusted size
          />
          <Typography variant="h3" color={colors.grey[100]} fontWeight="bold">
            Welcome to Vps Sync
          </Typography>
          <Typography variant="h6" color={colors.grey[300]} mt="10px">
            Providing high-quality and reliable RDP, VPS, and Proxy services to
            meet all your needs.
          </Typography>
          <Box mt="20px">
            <Button
              sx={{
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
                fontSize: '16px',
                fontWeight: 'bold',
                padding: '15px 30px',
              }}
              href="/signup"
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </Box>

      {/* SERVICES CARDS */}
      <Grid container spacing={3} mt="20px" justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <GlowingCard
            title="RDP Services"
            description="High-performance RDP services for seamless remote desktop access."
            icon={
              <ComputerIcon
                sx={{ color: colors.greenAccent[600], fontSize: '40px' }}
              />
            }
            size="large"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <GlowingCard
            title="VPS Services"
            description="Reliable and scalable VPS solutions to meet your business needs."
            icon={
              <CloudIcon
                sx={{ color: colors.greenAccent[600], fontSize: '40px' }}
              />
            }
            size="large"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <GlowingCard
            title="Proxy Services"
            description="Secure and fast proxy services for enhanced online privacy."
            icon={
              <SecurityIcon
                sx={{ color: colors.greenAccent[600], fontSize: '40px' }}
              />
            }
            size="large"
          />
        </Grid>
      </Grid>

      {/* ADDITIONAL CONTENT */}
      <Box textAlign="center" mt="40px">
        <Typography variant="h4" color={colors.grey[100]} fontWeight="bold">
          Why Choose Us?
        </Typography>
        <Typography variant="body1" color={colors.grey[300]} mt="10px">
          We offer the best services to help you with your remote desktop,
          virtual private servers, and online privacy needs. Our services are
          fast, secure, and reliable, ensuring you get the best performance and
          protection.
        </Typography>
      </Box>

      {/* FEATURES SECTION */}
      <Box mt="40px">
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GlowingCard
              title="User Friendly"
              description="Our services are easy to use with a user-friendly interface."
              icon={
                <ComputerIcon
                  sx={{ color: colors.greenAccent[600], fontSize: '30px' }}
                />
              }
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GlowingCard
              title="Fast"
              description="Experience unparalleled speed with our top-tier services."
              icon={
                <CloudIcon
                  sx={{ color: colors.greenAccent[600], fontSize: '30px' }}
                />
              }
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GlowingCard
              title="Support"
              description="Get 24/7 support for all your queries and issues."
              icon={
                <SecurityIcon
                  sx={{ color: colors.greenAccent[600], fontSize: '30px' }}
                />
              }
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GlowingCard
              title="Automatic Checks"
              description="Automatic checks and notifications for service status."
              icon={
                <ComputerIcon
                  sx={{ color: colors.greenAccent[600], fontSize: '30px' }}
                />
              }
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GlowingCard
              title="Safe Payments"
              description="Secure and fast payment methods for all transactions."
              icon={
                <CloudIcon
                  sx={{ color: colors.greenAccent[600], fontSize: '30px' }}
                />
              }
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <GlowingCard
              title="Warranty"
              description="Get a warranty on all VPS and RDP services."
              icon={
                <SecurityIcon
                  sx={{ color: colors.greenAccent[600], fontSize: '30px' }}
                />
              }
              size="small"
            />
          </Grid>
        </Grid>
      </Box>

      {/* FOOTER */}
      <Box textAlign="center" mt="40px" mb="20px">
        <Typography variant="body2" color={colors.grey[300]}>
          <Link to="/about" style={{ color: 'inherit', textDecoration: 'none' }}>
            About Us
          </Link>{' '}
          |{' '}
          <Link to="/privacy-policy" style={{ color: 'inherit', textDecoration: 'none' }}>
            Privacy Policy
          </Link>{' '}
          |{' '}
          <Link to="/terms-of-service" style={{ color: 'inherit', textDecoration: 'none' }}>
            Terms of Service
          </Link>{' '}
          |{' '}
          <Link to="/contact" style={{ color: 'inherit', textDecoration: 'none' }}>
            Contact Us
          </Link>
        </Typography>
      </Box>
      <Box textAlign="center" mt="40px" mb="20px">
        <Typography variant="body2" color={colors.grey[300]}>
          Made with{' '}
          <FavoriteIcon
            sx={{
              color: 'red',
              animation: 'heartbeat 1.5s infinite',
              '@keyframes heartbeat': {
                '0%': { transform: 'scale(1)' },
                '25%': { transform: 'scale(1.1)' },
                '50%': { transform: 'scale(1)' },
                '75%': { transform: 'scale(1.1)' },
                '100%': { transform: 'scale(1)' },
              },
              verticalAlign: 'middle', // Align icon with text
            }}
          />{' '}
          by Darky
        </Typography>
      </Box>
    </Box>
  );
};

export default HomePage;