import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Paper, Grid, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import QRCode from 'qrcode.react';
import PromocodeApi from '../../routes/product/promocodeRoutes'; // Adjust the import path as needed
import { tokens } from '../../theme';
import InvoiceAPi from '../../routes/product/invoiceRoutes'; // Adjust the import path as needed for'
// Import UPI icons
import BHIMIcon from './upi.png';
import GooglePayIcon from './googlepay.png';
import PhonePeIcon from './phonepe.png';

const CheckoutPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { state } = location;
  const {
    productType = '',
    selectedOption = '',
    selectedOs = '',
    selectedRam = '',
    selectedPort = '',
    username = '',
    password = '',
    pricing = '0',
    invoiceId,
  } = state || {};

  const [promoCode, setPromoCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [error, setError] = useState('');
  const [showPromoCodeInput, setShowPromoCodeInput] = useState(false);

  const totalAmount = pricing === 'Not available' ? 0 : parseFloat(pricing);
  const discountedAmount =
    parseFloat(totalAmount) * (1 - parseFloat(discount) / 100);

  const handleProceedCheckout = async () => {
    // Construct the productDetails object
    const productDetails = {
      type: productType,
      ipSeries: selectedOption, // Assuming selectedOption is the IP series
      os: selectedOs,
      username: username,
      password: password,
      ram: selectedRam,
      port: selectedPort,
    };
    await InvoiceAPi.updateInvoiceCustomer(
      invoiceId,
      'pending',
      productDetails,
      productType,
    );
    navigate('/user/invoice/upload', {
      state: { invoiceId }, // Pass the invoiceId to the next route if needed
    });
  };

  const handleApplyPromoCode = async () => {
    try {
      const response = await PromocodeApi.applyPromocode(promoCode);
      if (response.payload.discount) {
        setDiscount(response.payload.discount); // Assuming response contains discount percentage
        setError('');
      } else {
        setError('Invalid promo code');
        setDiscount(0);
      }
    } catch (error) {
      setError('Failed to apply promo code');
      setDiscount(0);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor:
          theme.palette.mode === 'dark' ? colors.primary[500] : 'white',
        minHeight: '100vh',
        p: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        elevation={4}
        sx={{
          backgroundColor:
            theme.palette.mode === 'dark'
              ? colors.primary[500]
              : colors.grey[50], // Updated color for light mode
          p: 6,
          borderRadius: 4,
          width: '100%',
          maxWidth: 1200,
          boxShadow: `0 6px 18px ${
            theme.palette.mode === 'dark'
              ? colors.primary[700]
              : colors.grey[300]
          }`,
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              color={
                theme.palette.mode === 'dark'
                  ? colors.greenAccent[500]
                  : colors.greenAccent[600]
              }
              mb={4}
              fontWeight="bold"
              textAlign="left"
            >
              Checkout
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="h5" mb={2}>
                <Typography component="span" color={colors.grey[100]}>
                  {`Product Type: `}
                </Typography>
                <Typography component="span" color={colors.greenAccent[500]}>
                  {productType}
                </Typography>
              </Typography>
              <Typography variant="h6" mb={2}>
                <Typography
                  component="span"
                  color={
                    theme.palette.mode === 'dark'
                      ? colors.grey[100]
                      : colors.grey[100]
                  }
                >
                  {`Selected Option: `}
                </Typography>
                <Typography
                  component="span"
                  color={
                    theme.palette.mode === 'dark'
                      ? colors.greenAccent[500]
                      : colors.greenAccent[500]
                  }
                >
                  {selectedOption}
                </Typography>
              </Typography>
              {selectedOs && (
                <Typography variant="h6" mb={2}>
                  <Typography
                    component="span"
                    color={
                      theme.palette.mode === 'dark'
                        ? colors.grey[100]
                        : colors.grey[100]
                    }
                  >
                    {`OS: `}
                  </Typography>
                  <Typography
                    component="span"
                    color={
                      theme.palette.mode === 'dark'
                        ? colors.greenAccent[500]
                        : colors.greenAccent[500]
                    }
                  >
                    {selectedOs}
                  </Typography>
                </Typography>
              )}
              {selectedRam && (
                <Typography variant="h6" mb={2}>
                  <Typography
                    component="span"
                    color={
                      theme.palette.mode === 'dark'
                        ? colors.grey[100]
                        : colors.grey[100]
                    }
                  >
                    {`RAM: `}
                  </Typography>
                  <Typography
                    component="span"
                    color={
                      theme.palette.mode === 'dark'
                        ? colors.greenAccent[500]
                        : colors.greenAccent[500]
                    }
                  >
                    {selectedRam}
                  </Typography>
                </Typography>
              )}
              {selectedPort && (
                <Typography variant="h6" mb={2}>
                  <Typography
                    component="span"
                    color={
                      theme.palette.mode === 'dark'
                        ? colors.grey[100]
                        : colors.grey[100]
                    }
                  >
                    {`Port: `}
                  </Typography>
                  <Typography
                    component="span"
                    color={
                      theme.palette.mode === 'dark'
                        ? colors.greenAccent[500]
                        : colors.greenAccent[500]
                    }
                  >
                    {selectedPort}
                  </Typography>
                </Typography>
              )}
              {username && (
                <Typography variant="h6" mb={2}>
                  <Typography
                    component="span"
                    color={
                      theme.palette.mode === 'dark'
                        ? colors.grey[100]
                        : colors.grey[100]
                    }
                  >
                    {`Username: `}
                  </Typography>
                  <Typography
                    component="span"
                    color={
                      theme.palette.mode === 'dark'
                        ? colors.greenAccent[500]
                        : colors.greenAccent[500]
                    }
                  >
                    {username}
                  </Typography>
                </Typography>
              )}
              {password && (
                <Typography variant="h6" mb={2}>
                  <Typography
                    component="span"
                    color={
                      theme.palette.mode === 'dark'
                        ? colors.grey[100]
                        : colors.grey[100]
                    }
                  >
                    {`Password: `}
                  </Typography>
                  <Typography
                    component="span"
                    color={
                      theme.palette.mode === 'dark'
                        ? colors.greenAccent[500]
                        : colors.greenAccent[500]
                    }
                  >
                    {password}
                  </Typography>
                </Typography>
              )}
              <Typography variant="h5" color={colors.greenAccent[500]} mb={4}>
                {`Total Amount: ₹${discountedAmount.toFixed(2)}`}
              </Typography>

              {!showPromoCodeInput && (
                <Button
                  variant="outlined"
                  sx={{
                    color: colors.greenAccent[500],
                    borderColor: colors.greenAccent[500],
                    '&:hover': {
                      borderColor: colors.greenAccent[500],
                      backgroundColor: colors.greenAccent[100],
                    },
                    borderRadius: 2,
                    px: 4,
                    py: 1.5,
                    mb: 3,
                  }}
                  onClick={() => setShowPromoCodeInput(true)}
                >
                  Apply Promo Code
                </Button>
              )}

              {showPromoCodeInput && (
                <>
                  <TextField
                    label="Promo Code"
                    value={promoCode}
                    onChange={e => setPromoCode(e.target.value)}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: colors.greenAccent[700],
                      color: colors.grey[100],
                      '&:hover': {
                        backgroundColor: colors.greenAccent[500],
                        boxShadow: `0 4px 8px ${colors.greenAccent[500]}`,
                      },
                      borderRadius: 2,
                      px: 4,
                      py: 1.5,
                    }}
                    onClick={handleApplyPromoCode}
                  >
                    Apply Promo Code
                  </Button>
                  {error && (
                    <Typography variant="body2" color="error" mt={2}>
                      {error}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? colors.grey[0]
                    : colors.grey[0],
                p: 4,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: `0 4px 12px ${colors.greenAccent[300]}`, // Simplified box shadow
              }}
            >
              <Typography
                variant="h6"
                color={colors.grey[100]}
                mb={2}
                fontWeight="bold"
              >
                Payment Options
              </Typography>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="body1" color={colors.grey[100]} mb={1}>
                  Scan QR Code or choose a payment method:
                </Typography>
                <QRCode
                  value={`upi://pay?pa=singh6206@axisbank&pn=VPS KING&am=${discountedAmount}&tn=test&cu=INR&url=https%3A%2F%2Fgoogle.com`}
                  size={256}
                />
                <Typography
                  variant="body2"
                  color={colors.grey[100]}
                  mt={2}
                  mb={1}
                >
                  Or use one of the payment apps:
                </Typography>
                <Box display="flex" gap={1}>
                  <img
                    src={BHIMIcon}
                    alt="BHIM"
                    style={{ width: 40, height: 40 }}
                  />
                  <img
                    src={GooglePayIcon}
                    alt="Google Pay"
                    style={{ width: 40, height: 40 }}
                  />
                  <img
                    src={PhonePeIcon}
                    alt="PhonePe"
                    style={{ width: 40, height: 40 }}
                  />
                </Box>
              </Box>
            </Box>
            <Button
              variant="contained"
              sx={{
                mt: 4,
                backgroundColor: colors.greenAccent[700],
                color: colors.grey[100],
                '&:hover': {
                  backgroundColor: colors.greenAccent[500],
                },
                px: 6,
                py: 1.5,
                borderRadius: 2,
              }}
              onClick={handleProceedCheckout}
            >
              Proceed to Checkout
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default CheckoutPage;
