import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getAuthToken = () => {
  return localStorage.getItem('token');
};

const VpsApi = {
  getVpsInstances: async () => {
    const token = getAuthToken();
    const response = await axios.get(`${API_URL}/products/vps/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  createVps: async vpsData => {
    const token = getAuthToken();
    const response = await axios.post(`${API_URL}/products/vps/`, vpsData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  updateVps: async (id, vpsData) => {
    const token = getAuthToken();
    const response = await axios.patch(`${API_URL}/products/vps/${id}`, vpsData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  deleteVps: async id => {
    const token = getAuthToken();
    await axios.delete(`${API_URL}/products/vps/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export default VpsApi;
