import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Avatar,
  Divider,
  Snackbar,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import EventIcon from '@mui/icons-material/Event';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DnsIcon from '@mui/icons-material/Dns';
import PercentIcon from '@mui/icons-material/Percent';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Header from '../../components/Header';
import PromocodeApi from '../../routes/product/promocodeRoutes';
import { tokens } from '../../theme';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

const PromocodeCategoriesPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [promocodes, setPromocodes] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedPromocodeId, setSelectedPromocodeId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [pageSize, setPageSize] = useState(5);

  const validationSchema = yup.object({
    code: yup.string().required('Code is required'),
    discount: yup
      .number()
      .required('Discount is required')
      .min(0, 'Discount must be at least 0')
      .max(100, 'Discount cannot be more than 100'),
    maxUses: yup
      .number()
      .required('Max Uses is required')
      .integer('Max Uses must be an integer')
      .min(1, 'Max Uses must be at least 1'),
    expiresAt: yup.date().required('Expires At is required'),
  });

  useEffect(() => {
    loadPromocodes();
  }, []);

  const loadPromocodes = async () => {
    try {
      const data = await PromocodeApi.getPromocodes();
      setPromocodes(data.payload.promocodes);
    } catch (error) {
      console.error('Error fetching promocodes:', error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setEditMode(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAddNew = async (values, { setSubmitting }) => {
    try {
      await PromocodeApi.createPromocode(values);
      setSnackbarOpen(true);
      setOpen(false);
      loadPromocodes();
    } catch (error) {
      console.error('Error adding promocode:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleEdit = async (values, { setSubmitting }) => {
    try {
      await PromocodeApi.updatePromocode(selectedPromocodeId, values);
      setSnackbarOpen(true);
      setOpen(false);
      loadPromocodes();
    } catch (error) {
      console.error('Error updating promocode:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleEditOpen = id => {
    const promocode = promocodes.find(promo => promo.id === id);
    setSelectedPromocodeId(id);
    setEditMode(true);
    setOpen(true);
  };

  const handleDelete = async id => {
    try {
      await PromocodeApi.deletePromocode(id);
      setPromocodes(promocodes.filter(promocode => promocode.id !== id));
    } catch (error) {
      console.error('Error deleting promocode:', error);
    }
  };

  const generateRandomCode = () => {
    return Math.random().toString(36).substring(2, 10).toUpperCase();
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    { field: 'code', headerName: 'Code', flex: 1 },
    { field: 'discount', headerName: 'Discount (%)', flex: 1 },
    { field: 'maxUses', headerName: 'Max Uses', flex: 1 },
    { field: 'timesUsed', headerName: 'Times Used', flex: 1 },
    { field: 'expiresAt', headerName: 'Expires At', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: params => (
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleEditOpen(params.row.id)}
          >
           <EditIcon sx={{ color: colors.greenAccent[500] }} />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)}>
            <DeleteIcon color="error" />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="PROMOCODES" subtitle="Manage Promocodes" />
      <Box mb="20px">
        <Button
          variant="contained"
          color="success"
          startIcon={<AddIcon />}
          onClick={handleOpen}
        >
          Add New Promocode
        </Button>

        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md"
          PaperProps={{
            sx: {
              borderRadius: '10px',
              boxShadow: `0px 0px 10px 2px ${colors.greenAccent[500]}`,
              padding: theme.spacing(4),
              backgroundColor: theme.palette.background.default,
              '&:hover': {
                boxShadow: `0px 0px 20px 4px ${colors.greenAccent[500]}`,
              },
            },
          }}
        >
          <DialogTitle>
            <Typography
              variant="h5"
              component="div"
              sx={{ color: theme.palette.text.primary }}
            >
              {editMode ? 'Edit Promocode' : 'Add New Promocode'}
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Formik
              initialValues={{
                code: generateRandomCode(),
                discount: '',
                maxUses: '',
                expiresAt: '',
              }}
              validationSchema={validationSchema}
              onSubmit={editMode ? handleEdit : handleAddNew}
            >
              {({
                isSubmitting,
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Avatar sx={{ bgcolor: colors.greenAccent[500] }}>
                        <DnsIcon />
                      </Avatar>
                      <TextField
                        autoFocus
                        margin="dense"
                        name="code"
                        label="Code"
                        type="text"
                        fullWidth
                        value={values.code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.code && !!errors.code}
                        helperText={touched.code && errors.code}
                        InputProps={{
                          sx: {
                            color: theme.palette.text.primary,
                            '&:focus': {
                              boxShadow: `0 0 10px ${theme.palette.success.main}`,
                            },
                          },
                        }}
                        sx={{ flexGrow: 1 }}
                        disabled={editMode} // Disable input if editing
                      />
                      <IconButton
                        color="primary"
                        onClick={() =>
                          setFieldValue('code', generateRandomCode())
                        }
                      >
                        <AutorenewIcon />
                      </IconButton>
                    </Box>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Avatar sx={{ bgcolor: colors.greenAccent[500] }}>
                        <PercentIcon />
                      </Avatar>
                      <TextField
                        margin="dense"
                        name="discount"
                        label="Discount (%)"
                        type="number"
                        fullWidth
                        value={values.discount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.discount && !!errors.discount}
                        helperText={touched.discount && errors.discount}
                        InputProps={{
                          sx: {
                            color: theme.palette.text.primary,
                            '&:focus': {
                              boxShadow: `0 0 10px ${theme.palette.success.main}`,
                            },
                          },
                        }}
                        sx={{ flexGrow: 1 }}
                      />
                    </Box>
                    <Divider sx={{ backgroundColor: theme.palette.divider }} />
                    <Box display="flex" alignItems="center" gap={2}>
                      <Avatar sx={{ bgcolor: colors.greenAccent[500] }}>
                        <DnsIcon />
                      </Avatar>
                      <TextField
                        margin="dense"
                        name="maxUses"
                        label="Max Uses"
                        type="number"
                        fullWidth
                        value={values.maxUses}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.maxUses && !!errors.maxUses}
                        helperText={touched.maxUses && errors.maxUses}
                        InputProps={{
                          sx: {
                            color: theme.palette.text.primary,
                            '&:focus': {
                              boxShadow: `0 0 10px ${theme.palette.success.main}`,
                            },
                          },
                        }}
                        sx={{ flexGrow: 1 }}
                      />
                    </Box>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Avatar sx={{ bgcolor: colors.greenAccent[500] }}>
                        <DateRangeIcon />
                      </Avatar>
                      <TextField
                        margin="dense"
                        name="expiresAt"
                        type="date"
                        fullWidth
                        value={values.expiresAt}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.expiresAt && !!errors.expiresAt}
                        helperText={touched.expiresAt && errors.expiresAt}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          sx: {
                            color: theme.palette.text.primary,
                            '&:focus': {
                              boxShadow: `0 0 10px ${theme.palette.success.main}`,
                            },
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <EventIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{ flexGrow: 1 }}
                      />
                    </Box>
                  </Box>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      color="error"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      color="success"
                      variant="contained"
                    >
                      {editMode ? 'Save Changes' : 'Add Promocode'}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: theme.palette.background.default,
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={promocodes}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        />
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="Operation successful!"
      />
    </Box>
  );
};

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <Box display="flex" alignItems="center">
        {/* Add any additional toolbar components here */}
      </Box>
      <GridToolbar />
    </GridToolbarContainer>
  );
};

export default PromocodeCategoriesPage;
