// src/context/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import AuthRoutes from './routes/auth/authRoutes'; // Corrected the import path
import { useNavigate, useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        AuthRoutes.setAuthToken(token);
        try {
          const data = await AuthRoutes.getUserRole();
          setUserRole(data.payload.role);
        } catch {
          AuthRoutes.logout();
          navigate('/login');
        }
      } else if (
        ![
          '/',
          '/login',
          '/signup',
          '/404',
          '/about',
          '/contact',
          '/privacy-policy',
          '/terms-of-service',
        ].includes(location.pathname)
      ) {
        navigate('/login');
      }
      setIsInitialized(true);
    };

    initializeAuth();
  }, [location, navigate]);

  return (
    <AuthContext.Provider value={{ userRole, isInitialized }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
