import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Avatar,
  Divider,
  Snackbar,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DnsIcon from '@mui/icons-material/Dns';
import MemoryIcon from '@mui/icons-material/Memory';
import StorageIcon from '@mui/icons-material/Storage';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ComputerIcon from '@mui/icons-material/Computer';
import Header from '../../components/Header';
import ProxyApi from '../../routes/product/proxyRoutes';
import { tokens } from '../../theme';

const ProxyCategoriesPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [newCategory, setNewCategory] = useState({
    series: '',
    portNumber: '',
    ram: '',
    cores: '',
    pricing: '',
  });
  const [editMode, setEditMode] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [pageSize, setPageSize] = useState(5); // State for pageSize

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    try {
      const data = await ProxyApi.getProxyInstances();
      setCategories(data.payload.proxies);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setEditMode(false);
    setNewCategory({
      series: '',
      portNumber: '',
      ram: '',
      cores: '',
      pricing: '',
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setNewCategory({ ...newCategory, [name]: value });
  };

  const handleAddNew = async () => {
    try {
      const newCategoryData = {
        series: newCategory.series,
        portNumber: parseInt(newCategory.portNumber, 10) || 0,
        ram: `${newCategory.ram} RAM ${newCategory.cores} CPU`,
        pricing: parseFloat(newCategory.pricing) || 0.0,
      };
      await ProxyApi.createProxy(newCategoryData);
      setSnackbarOpen(true);
      setOpen(false);
      loadCategories();
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  const handleEdit = async () => {
    try {
      const updatedCategoryData = {
        series: newCategory.series,
        portNumber: parseInt(newCategory.portNumber, 10) || 0,
        ram: `${newCategory.ram} RAM ${newCategory.cores} CPU`,
        pricing: parseFloat(newCategory.pricing) || 0.0,
      };
      await ProxyApi.updateProxy(selectedCategoryId, updatedCategoryData);
      setCategories(
        categories.map(cat =>
          cat.id === selectedCategoryId
            ? { ...cat, ...updatedCategoryData }
            : cat,
        ),
      );
      setSnackbarOpen(true);
      setOpen(false);
      loadCategories();
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  const handleEditOpen = id => {
    const category = categories.find(cat => cat.id === id);
    const [ram, cores] = category.ram.split(' RAM ');
    setNewCategory({
      series: category.series,
      portNumber: category.port.toString(),
      ram,
      cores: cores.replace(' CPU', ''),
      pricing: category.pricing.toString(),
    });
    setSelectedCategoryId(id);
    setEditMode(true);
    setOpen(true);
  };

  const handleDelete = async id => {
    try {
      await ProxyApi.deleteProxy(id);
      setCategories(categories.filter(category => category.id !== id));
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    { field: 'series', headerName: 'Series', flex: 1 },
    {
      field: 'port',
      headerName: 'Port Number',
      flex: 1,
    },
    { field: 'ram', headerName: 'RAM + Cores', flex: 1 },
    { field: 'pricing', headerName: 'Pricing', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: params => (
        <Box>
          <IconButton
            color="secondary"
            onClick={() => handleEditOpen(params.row.id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)}>
            <DeleteIcon color="error" />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="PROXY CATEGORIES" subtitle="Manage Proxy Categories" />
      <Box mb="20px">
        <Button
          variant="contained"
          color="success"
          startIcon={<AddIcon />}
          onClick={handleOpen}
        >
          Add New Category
        </Button>

        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md"
          PaperProps={{
            sx: {
              borderRadius: '10px',
              boxShadow: `0px 0px 10px 2px ${colors.greenAccent[500]}`,
              padding: theme.spacing(4),
              backgroundColor: theme.palette.background.default,
              '&:hover': {
                boxShadow: `0px 0px 20px 4px ${colors.greenAccent[500]}`,
              },
            },
          }}
        >
          <DialogTitle>
            <Typography
              variant="h5"
              component="div"
              sx={{ color: theme.palette.text.primary }}
            >
              {editMode ? 'Edit Category' : 'Add New Category'}
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar sx={{ bgcolor: colors.greenAccent[500] }}>
                  <DnsIcon />
                </Avatar>
                <TextField
                  autoFocus
                  margin="dense"
                  name="series"
                  label="Series"
                  type="text"
                  fullWidth
                  value={newCategory.series}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      color: theme.palette.text.primary,
                      '&:focus': {
                        boxShadow: `0 0 10px ${theme.palette.success.main}`,
                      },
                    },
                  }}
                  sx={{ flexGrow: 1 }}
                />
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar sx={{ bgcolor: colors.greenAccent[500] }}>
                  <ComputerIcon />
                </Avatar>
                <TextField
                  margin="dense"
                  name="portNumber"
                  label="Port Number"
                  type="text"
                  fullWidth
                  value={newCategory.portNumber}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      color: theme.palette.text.primary,
                      '&:focus': {
                        boxShadow: `0 0 10px ${theme.palette.success.main}`,
                      },
                    },
                  }}
                  sx={{ flexGrow: 1 }}
                />
              </Box>
              <Divider sx={{ backgroundColor: theme.palette.divider }} />
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar sx={{ bgcolor: colors.greenAccent[500] }}>
                  <MemoryIcon />
                </Avatar>
                <TextField
                  margin="dense"
                  name="ram"
                  label="RAM"
                  type="text"
                  fullWidth
                  value={newCategory.ram}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      color: theme.palette.text.primary,
                      '&:focus': {
                        boxShadow: `0 0 10px ${theme.palette.success.main}`,
                      },
                    },
                  }}
                  sx={{ flexGrow: 1 }}
                />
                <Avatar sx={{ bgcolor: colors.greenAccent[500] }}>
                  <StorageIcon />
                </Avatar>
                <TextField
                  margin="dense"
                  name="cores"
                  label="Cores"
                  type="text"
                  fullWidth
                  value={newCategory.cores}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      color: theme.palette.text.primary,
                      '&:focus': {
                        boxShadow: `0 0 10px ${theme.palette.success.main}`,
                      },
                    },
                  }}
                  sx={{ flexGrow: 1 }}
                />
              </Box>
              <Divider sx={{ backgroundColor: theme.palette.divider }} />
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar sx={{ bgcolor: colors.greenAccent[500] }}>
                  <AttachMoneyIcon />
                </Avatar>
                <TextField
                  margin="dense"
                  name="pricing"
                  label="Pricing"
                  type="text"
                  fullWidth
                  value={newCategory.pricing}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      color: theme.palette.text.primary,
                      '&:focus': {
                        boxShadow: `0 0 10px ${theme.palette.success.main}`,
                      },
                    },
                  }}
                  sx={{ flexGrow: 1 }}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            {editMode ? (
              <Button onClick={handleEdit}>Save Changes</Button>
            ) : (
              <Button
                onClick={handleAddNew}
                color="secondary"
                startIcon={<CheckCircleIcon />}
              >
                Add
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: theme.palette.background.default,
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={categories}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={pageSize} // Set pageSize from state
          onPageSizeChange={newPageSize => setPageSize(newPageSize)} // Handle pageSize change
          rowsPerPageOptions={[5, 10, 20]}
        />
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message="Category Updated Successfully"
        action={
          <IconButton
            size="medium"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CheckCircleIcon fontSize="medium" />
          </IconButton>
        }
      />
    </Box>
  );
};

export default ProxyCategoriesPage;
