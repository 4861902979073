import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InvoiceApi from '../../routes/transaction/invoiceRoutes'; // Adjust the path according to your project structure
import {
  Box,
  Button,
  Input,
  Typography,
  Paper,
  styled,
  Avatar,
  CircularProgress,
  Alert,
} from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: '20px',
  padding: '10px 20px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  boxShadow: `0 0 10px ${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: `0 0 20px ${theme.palette.primary.main}`,
  },
}));

const UploadInvoicePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [file, setFile] = useState(null);
  const [utrNumber, setUtrNumber] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false); // State to track loading

  const { state } = location;
  const { invoiceId } = state || null;

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  };

  const handleUtrNumberChange = event => {
    setUtrNumber(event.target.value);
  };

  const handleSubmit = async () => {
    if (!file && !utrNumber) {
      setError('Please provide either a file or UTR number.');
      return;
    }

    setLoading(true); // Start loading
    setError('');
    setSuccess('');

    try {
      if (file) {
        await InvoiceApi.uploadInvoice(invoiceId, file);
      }
      await InvoiceApi.updateInvoiceCustomer(invoiceId, 'paid', utrNumber || null);
      setSuccess('Invoice uploaded successfully!');
      setPreview(null); // Clear preview after successful upload
      navigate('/user/pending-invoices'); // Navigate to a different page if needed
    } catch (err) {
      console.error('Error uploading invoice:', err);
      setError('Failed to upload the invoice. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const isSubmitEnabled = file || utrNumber;

  return (
    <Paper
      elevation={3}
      style={{
        padding: '30px',
        maxWidth: '600px',
        margin: 'auto',
        marginTop: '50px',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Upload Invoice
      </Typography>
      <Input type="file" onChange={handleFileChange} />
      <Box mt={2}>
        <Input
          type="text"
          placeholder="Enter UTR Number"
          value={utrNumber}
          onChange={handleUtrNumberChange}
          fullWidth
        />
      </Box>
      {preview && (
        <Box mt={2}>
          <Typography variant="h6">Preview:</Typography>
          <Avatar
            variant="rounded"
            src={preview}
            alt="Invoice Preview"
            sx={{ width: '100%', height: 'auto', mt: 2 }}
          />
        </Box>
      )}
      {error && (
        <Typography color="error" mt={2}>
          {error}
        </Typography>
      )}
      {success && (
        <Typography color="primary" mt={2}>
          {success}
        </Typography>
      )}
      <Box mt={2}>
        <Alert severity="warning">
          Please enter a real UTR number. Fake or incorrect UTR numbers will not be eligible for refunds.
        </Alert>
      </Box>
      <Box mt={2} display="flex" alignItems="center">
        <StyledButton
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          disabled={!isSubmitEnabled || loading} // Disable button based on conditions
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
        </StyledButton>
      </Box>
    </Paper>
  );
};

export default UploadInvoicePage;
