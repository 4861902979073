import {
  Box,
  Button,
  Typography,
  Grid,
  useTheme,
  IconButton,
  Container,
} from '@mui/material';
import CloudIcon from '@mui/icons-material/Cloud';
import WebIcon from '@mui/icons-material/Web';
import SecurityIcon from '@mui/icons-material/Security';
import SupportIcon from '@mui/icons-material/Support';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SpeedIcon from '@mui/icons-material/Speed';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { tokens, ColorModeContext } from '../../theme';

const VPSAndHostingPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Container maxWidth="lg">
      <Box m="20px">
        {/* Header */}
        <Typography variant="h3" gutterBottom align="center">
          VPS and Hosting Services
        </Typography>

        <Typography variant="body1" paragraph align="center">
          At VPS King, we offer cutting-edge VPS and web hosting solutions
          designed to provide you with unparalleled speed, reliability, and
          flexibility. Whether you're hosting a website, running an online
          store, or managing a server, our services are built to meet your
          demands.
        </Typography>

        {/* VPS Section */}
        <Box mt="40px">
          <Typography variant="h4" gutterBottom align="center">
            Why Choose Our VPS Services?
          </Typography>

          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <CloudIcon sx={{ fontSize: '40px', color: 'green' }} />
                <Typography variant="h6" ml="10px">
                  Cloud VPS Hosting
                </Typography>
              </Box>
              <Typography variant="body2" mt="10px" align="center">
                Enjoy scalable and flexible cloud-based VPS hosting. Our VPS
                servers offer the best performance and are perfect for websites
                and applications of any size.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <SpeedIcon sx={{ fontSize: '40px', color: 'green' }} />
                <Typography variant="h6" ml="10px">
                  High Performance
                </Typography>
              </Box>
              <Typography variant="body2" mt="10px" align="center">
                Our VPS servers are built with top-of-the-line hardware to
                ensure lightning-fast performance and seamless operation, even
                during traffic spikes.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <SecurityIcon sx={{ fontSize: '40px', color: 'green' }} />
                <Typography variant="h6" ml="10px">
                  Robust Security
                </Typography>
              </Box>
              <Typography variant="body2" mt="10px" align="center">
                We prioritize your security. Our VPS services come with advanced
                DDoS protection and firewalls to ensure your data is safe.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <SupportIcon sx={{ fontSize: '40px', color: 'green' }} />
                <Typography variant="h6" ml="10px">
                  24/7 Support
                </Typography>
              </Box>
              <Typography variant="body2" mt="10px" align="center">
                Our dedicated support team is available around the clock to
                assist you with any technical issues or questions you may have.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <CheckCircleIcon sx={{ fontSize: '40px', color: 'green' }} />
                <Typography variant="h6" ml="10px">
                  Uptime Guarantee
                </Typography>
              </Box>
              <Typography variant="body2" mt="10px" align="center">
                We provide a 99.9% uptime guarantee, ensuring your website or
                server is always available to your audience.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Web Hosting Section */}
        <Box mt="40px">
          <Typography variant="h4" gutterBottom align="center">
            Web Hosting Services
          </Typography>
          <Typography variant="body1" paragraph align="center">
            Our web hosting solutions provide the foundation for your website.
            With lightning-fast speeds and reliable uptime, your site will
            always be online and performing at its best.
          </Typography>

          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <WebIcon sx={{ fontSize: '40px', color: 'blue' }} />
                <Typography variant="h6" ml="10px">
                  Domain Acquisition
                </Typography>
              </Box>
              <Typography variant="body2" mt="10px" align="center">
                Find the perfect domain name for your website and secure it
                through our platform. Our domain registration process is simple
                and quick, giving you full control of your domain.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <SpeedIcon sx={{ fontSize: '40px', color: 'blue' }} />
                <Typography variant="h6" ml="10px">
                  High-Speed Hosting
                </Typography>
              </Box>
              <Typography variant="body2" mt="10px" align="center">
                Enjoy top-tier hosting that ensures your website runs smoothly,
                with ultra-fast load times and superior uptime.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* CTA */}
        <Box textAlign="center" mt="40px">
          <Typography variant="h5" mb="20px">
            Ready to Get Started with Our VPS or Hosting Services?
          </Typography>
          <Button
            sx={{
              backgroundColor: 'green',
              color: 'white',
              padding: '10px 20px',
              fontSize: '16px',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: 'darkgreen',
              },
            }}
            href="/signup"
          >
            Sign Up Now
          </Button>
        </Box>
      <Box textAlign="center" mt="40px" mb="20px">
        <Typography variant="body2" color={colors.grey[300]}>
          Made with{' '}
          <FavoriteIcon
            sx={{
              color: 'red',
              animation: 'heartbeat 1.5s infinite',
              '@keyframes heartbeat': {
                '0%': { transform: 'scale(1)' },
                '25%': { transform: 'scale(1.1)' },
                '50%': { transform: 'scale(1)' },
                '75%': { transform: 'scale(1.1)' },
                '100%': { transform: 'scale(1)' },
              },
              verticalAlign: 'middle',
            }}
          />{' '}
          by{' '}
          <a
            href="https://t.me/darky_bhaiya"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: colors.grey[300],
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
          >
            Darky
          </a>
        </Typography>
      </Box>
      </Box>
    </Container>
  );
};

export default VPSAndHostingPage;
