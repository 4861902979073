import React from 'react';
import { Box, Typography, Container, Grid, Paper } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LockIcon from '@mui/icons-material/Lock';

const PrivacyPolicy = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: { xs: '1rem', sm: '2rem' },
        width: '100%',
        borderRadius: '10px',
        maxHeight: '90vh',
        overflowY: 'auto',
      }}
    >
      <Paper
        elevation={3}
        sx={{ padding: { xs: '1rem', sm: '2rem' }, width: '100%', borderRadius: '10px' }}
      >
        <Box>
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{ fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            gutterBottom
            sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
          >
            Your privacy and security are important to us.
          </Typography>

          <Grid container spacing={4} mt={5}>
            {/* Information Collection */}
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" mb={2}>
                <InfoIcon
                  fontSize="large"
                  color="primary"
                  sx={{ marginRight: '1rem', fontSize: { xs: '2rem', sm: '2.5rem' } }}
                />
                <Typography variant="h5" fontWeight="bold" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                  1. Information Collection
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
                VPS KING collects personal information from you when you:
                <ul>
                  <li>Register for an account</li>
                  <li>Purchase our services</li>
                  <li>Contact us for support or inquiries</li>
                </ul>
                This includes your name, email, and billing details necessary to
                provide our services.
              </Typography>
            </Grid>

            {/* Use of Information */}
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" mb={2}>
                <PrivacyTipIcon
                  fontSize="large"
                  color="primary"
                  sx={{ marginRight: '1rem', fontSize: { xs: '2rem', sm: '2.5rem' } }}
                />
                <Typography variant="h5" fontWeight="bold" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                  2. Use of Information
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
                We use your information to:
                <ul>
                  <li>Provide and improve our services</li>
                  <li>Process transactions</li>
                  <li>Respond to customer support</li>
                  <li>Comply with legal obligations</li>
                </ul>
              </Typography>
            </Grid>

            {/* Protection of Information */}
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" mb={2}>
                <SecurityIcon
                  fontSize="large"
                  color="primary"
                  sx={{ marginRight: '1rem', fontSize: { xs: '2rem', sm: '2.5rem' } }}
                />
                <Typography variant="h5" fontWeight="bold" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                  3. Protection of Information
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
                We take security seriously. Industry-standard measures are used
                to safeguard your data from unauthorized access or misuse.
              </Typography>
            </Grid>

            {/* Sharing of Information */}
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" mb={2}>
                <LockIcon
                  fontSize="large"
                  color="primary"
                  sx={{ marginRight: '1rem', fontSize: { xs: '2rem', sm: '2.5rem' } }}
                />
                <Typography variant="h5" fontWeight="bold" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                  4. Sharing of Information
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
                We do not share your personal information except when required
                by law or to protect our legal interests.
              </Typography>
            </Grid>

            {/* Changes to Policy */}
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" mb={2}>
                <InfoIcon
                  fontSize="large"
                  color="primary"
                  sx={{ marginRight: '1rem', fontSize: { xs: '2rem', sm: '2.5rem' } }}
                />
                <Typography variant="h5" fontWeight="bold" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                  5. Changes to This Policy
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
                VPS KING reserves the right to update this policy. We encourage
                users to review the policy periodically for changes.
              </Typography>
            </Grid>

            {/* Contact Information */}
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" mb={2}>
                <ContactMailIcon
                  fontSize="large"
                  color="primary"
                  sx={{ marginRight: '1rem', fontSize: { xs: '2rem', sm: '2.5rem' } }}
                />
                <Typography variant="h5" fontWeight="bold" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                  6. Contact Information
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
                If you have any questions or concerns, feel free to reach us at:
                <strong> support@vpsking.com</strong>.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;
