// src/App.js
import { useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import UserSidebar from './scenes/global/UserSidebar';
import Home from './scenes/home/home';
import LoginPage from './scenes/auth/login';
import SignupPage from './scenes/auth/signup';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import { AuthProvider, useAuth } from './AuthContext';
import ProtectedRoutes from './ProtectedRoutes';
import AboutUs from './scenes/terms/AboutUs';
import ContactUs from './scenes/terms/ContactUs';
import PrivacyPolicy from './scenes/terms/PrivacyPolicy';
import TermsOfService from './scenes/terms/TermsOfService';
function AppContent() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { userRole, isInitialized } = useAuth();
  const location = useLocation();

  const homePaths = [
    '/',
    '/login',
    '/signup',
    '/404',
    '/about',
    '/contact',
    '/privacy-policy',
    '/terms-of-service',
  ];
  const isHome = homePaths.includes(location.pathname);

  if (!isInitialized) {
    // You can return a loading spinner or similar here
    return <div>Loading...</div>;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            {/* Add your 404 page route here */}
            {/* <Route path="/404" element={<NotFoundPage />} /> */}
          </Routes>
          {!isHome && userRole && (
            <>
              {userRole === 'admin' ? (
                <Sidebar isSidebar={isSidebar} />
              ) : (
                <UserSidebar isSidebar={isSidebar} />
              )}
              <main className="content">
                <Topbar setIsSidebar={setIsSidebar} />
                <ProtectedRoutes userRole={userRole} />
              </main>
            </>
          )}
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}
