import React from 'react';
import { Box, Typography, Grid, Paper, IconButton, useTheme } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import SecurityIcon from '@mui/icons-material/Security';
import VirusScannerIcon from '@mui/icons-material/BugReport';
import ExplicitIcon from '@mui/icons-material/Explicit';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import BackupIcon from '@mui/icons-material/Backup';
import PaymentIcon from '@mui/icons-material/Payment';
import CancelIcon from '@mui/icons-material/Cancel';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { tokens, ColorModeContext } from '../../theme';
import FavoriteIcon from '@mui/icons-material/Favorite';
const TermsAndService = () => {
      const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box p={4} bgcolor="background.default">
      <Typography variant="h3" align="center" gutterBottom fontWeight="bold">
        Terms and Services
      </Typography>
      <Grid container spacing={4}>
        {/* Spamming Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold">
              <GavelIcon sx={{ mr: 2 }} /> Spamming
            </Typography>
            <Typography>
              Sending unsolicited bulk and/or commercial messages (spamming) is
              prohibited. vpsking.in reserves the right to determine what
              constitutes a violation.
            </Typography>
          </Paper>
        </Grid>

        {/* Virus and Destructive Activities Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold">
              <VirusScannerIcon sx={{ mr: 2 }} /> Viruses and Destructive
              Activities
            </Typography>
            <Typography>
              The use of vpsking.in’s services to create or send Internet
              viruses, worms, or engage in denial of service attacks is
              prohibited.
            </Typography>
          </Paper>
        </Grid>

        {/* Illegal Use Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold">
              <GavelIcon sx={{ mr: 2 }} /> Illegal Use
            </Typography>
            <Typography>
              Services may only be used for lawful purposes. Transmission of any
              information in violation of the law is prohibited.
            </Typography>
          </Paper>
        </Grid>

        {/* System and Network Abuse Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold">
              <SecurityIcon sx={{ mr: 2 }} /> System and Network Abuse
            </Typography>
            <Typography>
              Violations of system or network security are prohibited. Actions
              like mail bombing, flooding, or unauthorized access are grounds
              for criminal liability.
            </Typography>
          </Paper>
        </Grid>

        {/* Pornography Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold">
              <ExplicitIcon sx={{ mr: 2 }} /> Pornography
            </Typography>
            <Typography>
              The use of vpsking.in’s services for pornography is prohibited. We
              will notify law enforcement if we become aware of any such
              activity.
            </Typography>
          </Paper>
        </Grid>

        {/* Anti-Piracy Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold">
              <PrivacyTipIcon sx={{ mr: 2 }} /> Anti-Piracy
            </Typography>
            <Typography>
              vpsking.in does not permit the distribution of illegal or pirated
              software through its services.
            </Typography>
          </Paper>
        </Grid>

        {/* Backup Policy Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold">
              <BackupIcon sx={{ mr: 2 }} /> Backup Policy
            </Typography>
            <Typography>
              Customers are responsible for backing up their own data.
              vpsking.in does not guarantee the backup of any content and cannot
              be held responsible for data loss.
            </Typography>
          </Paper>
        </Grid>

        {/* Payment Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold">
              <PaymentIcon sx={{ mr: 2 }} /> Payment
            </Typography>
            <Typography>
              Services may be suspended if payments are not made by the due
              date. vpsking.in is not responsible for any loss caused by service
              disruptions.
            </Typography>
          </Paper>
        </Grid>

        {/* Refund Policy Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold">
              <CancelIcon sx={{ mr: 2 }} /> Refund Policy
            </Typography>
            <Typography>
              Registering with us or subscribing to our service means you agree
              to our refund policy, we have enacted refund policy to provide
              better user experience, the policy is based on some guidances and
              information that you should follow. Kindly read it carefully to
              avoid any issues that may arise. This policy can be changed at any
              time with or without any notice. To process the refund for RDP
              plans, we need a conclusive reason before you ask for refund. The
              user must provide any conclusive reason, we will try to resolve
              the issue or a refund will be issued. You are no longer guaranteed
              a refund if you violate our TOS, we may still provide a partial
              refund but we are not obligated to do so if you have done
              something atrocious. All advance deposits are not eligible for
              refund, you can either use it for ordering new services or paying
              your existing invoices. Please allow up to 24 hours for the refund
              to be given, all refunds will be processed through the same
              payment processor that you've used to pay the invoice in first
              place. In case we are not able to refund any payment via payment
              processor due to any reason, then refund amount will be posted as
              funds to customer's account. We only offer refunds if the hosting
              or VPS expires before the said period (10-15 days warranty). No
              refunds will be provided beyond this window.
            </Typography>
          </Paper>
        </Grid>

        {/* Actions Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom fontWeight="bold">
              Actions Taken by vpsking.in
            </Typography>
            <Typography>
              Violation of these terms may result in suspension, termination, or
              other legal action. vpsking.in reserves the right to refuse
              service and will not issue credits for outages caused by such
              violations.
            </Typography>
          </Paper>
        </Grid>
          </Grid>
                <Box textAlign="center" mt="40px" mb="20px">
        <Typography variant="body2" color={colors.grey[300]}>
          Made with{' '}
          <FavoriteIcon
            sx={{
              color: 'red',
              animation: 'heartbeat 1.5s infinite',
              '@keyframes heartbeat': {
                '0%': { transform: 'scale(1)' },
                '25%': { transform: 'scale(1.1)' },
                '50%': { transform: 'scale(1)' },
                '75%': { transform: 'scale(1.1)' },
                '100%': { transform: 'scale(1)' },
              },
              verticalAlign: 'middle',
            }}
          />{' '}
          by{' '}
          <a
            href="https://t.me/darky_bhaiya"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: colors.grey[300],
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
          >
            Darky
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsAndService;
