// BuyApi.js

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getAuthToken = () => {
  return localStorage.getItem('token');
};

const BuyApi = {
  getVpsOptions: async () => {
    const token = getAuthToken();
    const response = await axios.get(`${API_URL}/products/vps/buy`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  getProxyOptions: async () => {
    const token = getAuthToken();
    const response = await axios.get(`${API_URL}/products/proxy/buy`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  buyVps: async vpsId => {
    const token = getAuthToken();
    const response = await axios.post(
      `${API_URL}/products/vps/buy`,
      { vpsId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  },

  buyProxy: async (proxyId, username, password) => {
    const token = getAuthToken();
    const data = {
      proxyId,
      username,
      password,
    };
    const response = await axios.post(`${API_URL}/products/proxy/buy`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },
};

export default BuyApi;
