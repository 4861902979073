import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getAuthToken = () => {
  return localStorage.getItem('token');
};

const PromocodeApi = {
  getPromocodes: async () => {
    const token = getAuthToken();
    const response = await axios.get(`${API_URL}/products/promocode`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  createPromocode: async promocodeData => {
    const token = getAuthToken();
    const response = await axios.post(
      `${API_URL}/products/promocode`,
      promocodeData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  },

  updatePromocode: async (id, promocodeData) => {
    const token = getAuthToken();
    const response = await axios.patch(
      `${API_URL}/products/promocode/${id}`,
      promocodeData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  },

  deletePromocode: async id => {
    const token = getAuthToken();
    await axios.delete(`${API_URL}/products/promocode/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  applyPromocode: async code => {
    const token = getAuthToken();
    const response = await axios.post(
      `${API_URL}/products/promocode/apply`,
      { code },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  },
};

export default PromocodeApi;
