import Dashboard from './scenes/dashboard';
import UserPendingInvoices from './scenes/invoices/userPendingInvoices';
import UserDashboard from './scenes/dashboard/userDashboard';
import VpsPage from './scenes/product/VpsPage';
import ProxyPage from './scenes/product/ProxyPage';
import BuyPage from './scenes/product/BuyPage';
import PromoCode from './scenes/product/PromoCode';
import CheckoutPage from './scenes/product/CheckoutPage';
import UploadInvoicePage from './scenes/product/UploadInvoicePage';
import PaymentHistoryPage from './scenes/invoices/PaymentHistory';
import AdminInvoices from './scenes/invoices/adminInvoices';
import MyProxyPage from './scenes/product/MyProxyPage';
import MyVpsPage from './scenes/product/MyVpsPage';
const adminRoutes = [
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/categories/vps', element: <VpsPage /> },
  { path: '/categories/proxy', element: <ProxyPage /> },
  { path: '/promocode', element: <PromoCode /> },
  { path: '/invoices', element: <AdminInvoices /> },
];

const customerRoutes = [
  { path: '/buy', element: <BuyPage /> },
  { path: '/user/pending-invoices', element: <UserPendingInvoices /> },
  { path: '/user/checkout', element: <CheckoutPage /> },
  { path: '/user/invoice/upload', element: <UploadInvoicePage /> },
  { path: '/user/my-proxy', element: <MyProxyPage /> },
  { path: '/user/my-vps', element: <MyVpsPage /> },
  { path: '/user/payment-history', element: <PaymentHistoryPage /> },
];

export { adminRoutes, customerRoutes };
