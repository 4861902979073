import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getAuthToken = () => {
  return localStorage.getItem('token');
};

const ProxyApi = {
  getProxyInstances: async () => {
    const token = getAuthToken();
    const response = await axios.get(`${API_URL}/products/proxy/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  createProxy: async proxyData => {
    const token = getAuthToken();
    const response = await axios.post(`${API_URL}/products/proxy/`, proxyData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  updateProxy: async (id, proxyData) => {
    const token = getAuthToken();
    const response = await axios.patch(
      `${API_URL}/products/proxy/${id}`,
      proxyData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  },

  deleteProxy: async id => {
    const token = getAuthToken();
    await axios.delete(`${API_URL}/products/proxy/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export default ProxyApi;
