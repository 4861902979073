import {
  Box,
  Button,
  Typography,
  Grid,
  useTheme,
  Paper,
  IconButton,
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import TelegramIcon from '@mui/icons-material/Telegram';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { tokens } from '../../theme';

const ContactUs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        m: { xs: '20px', sm: '40px' },
        p: { xs: '20px', sm: '40px' },
        bgcolor: 'background.default',
        minHeight: '50vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        align="center"
        sx={{ fontWeight: 'bold', fontSize: { xs: '2rem', sm: '3rem' } }}
      >
        Contact Us
      </Typography>
      <Typography
        variant="body1"
        paragraph
        align="center"
        mb="40px"
        sx={{ maxWidth: '700px', lineHeight: 1.6, fontSize: { xs: '0.875rem', sm: '1rem' } }}
      >
        We're here to help! Whether you have a question about our services, need
        assistance with your account, or want to know more about what we offer,
        feel free to reach out to us through any of the methods below.
      </Typography>

      <Box
        sx={{
          maxWidth: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="stretch"
          sx={{ px: { xs: 1, sm: 0 } }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={3}
              sx={{ p: 2, textAlign: 'center', height: '100%' }}
            >
              <WhatsAppIcon sx={{ fontSize: '40px', color: 'green' }} />
              <Typography variant="h6" mt="15px" fontWeight="bold" fontSize={{ xs: '1rem', sm: '1.25rem' }}>
                WhatsApp
              </Typography>
              <Typography variant="body2" mt="10px" color="textSecondary" fontSize={{ xs: '0.875rem', sm: '1rem' }}>
                Reach out to us on WhatsApp for instant support: +91 7488 006 309
              </Typography>
              <Button
                variant="contained"
                color="success"
                startIcon={<WhatsAppIcon />}
                href="https://wa.me/916206959323"
                target="_blank"
                sx={{ mt: '15px', width: '100%' }}
              >
                Chat on WhatsApp
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={3}
              sx={{ p: 2, textAlign: 'center', height: '100%' }}
            >
              <TelegramIcon sx={{ fontSize: '40px', color: '#0088cc' }} />
              <Typography variant="h6" mt="15px" fontWeight="bold" fontSize={{ xs: '1rem', sm: '1.25rem' }}>
                Telegram
              </Typography>
              <Typography variant="body2" mt="10px" color="textSecondary" fontSize={{ xs: '0.875rem', sm: '1rem' }}>
                Join us on Telegram: @VPSKingSupport
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<TelegramIcon />}
                href="https://t.me/VPSKingSupport"
                target="_blank"
                sx={{ mt: '15px', width: '100%' }}
              >
                Chat on Telegram
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={3}
              sx={{ p: 2, textAlign: 'center', height: '100%' }}
            >
              <EmailIcon sx={{ fontSize: '40px', color: 'blue' }} />
              <Typography variant="h6" mt="15px" fontWeight="bold" fontSize={{ xs: '1rem', sm: '1.25rem' }}>
                Email
              </Typography>
              <Typography variant="body2" mt="10px" color="textSecondary" fontSize={{ xs: '0.875rem', sm: '1rem' }}>
                Send us an email at: support@vpsking.com
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={3}
              sx={{ p: 2, textAlign: 'center', height: '100%' }}
            >
              <PhoneIcon sx={{ fontSize: '40px', color: 'orange' }} />
              <Typography variant="h6" mt="15px" fontWeight="bold" fontSize={{ xs: '1rem', sm: '1.25rem' }}>
                Phone
              </Typography>
              <Typography variant="body2" mt="10px" color="textSecondary" fontSize={{ xs: '0.875rem', sm: '1rem' }}>
                Call us directly: +91 6206 959 323
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Box textAlign="center" mt="40px" mb="20px">
        <Typography variant="body2" color={colors.grey[300]} fontSize={{ xs: '0.75rem', sm: '1rem' }}>
          Made with{' '}
          <FavoriteIcon
            sx={{
              color: 'red',
              animation: 'heartbeat 1.5s infinite',
              '@keyframes heartbeat': {
                '0%': { transform: 'scale(1)' },
                '25%': { transform: 'scale(1.1)' },
                '50%': { transform: 'scale(1)' },
                '75%': { transform: 'scale(1.1)' },
                '100%': { transform: 'scale(1)' },
              },
              verticalAlign: 'middle',
            }}
          />{' '}
          by{' '}
          <a
            href="https://t.me/darky_bhaiya"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: colors.grey[300],
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
          >
            Darky
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default ContactUs;
