// src/ProtectedRoutes.js
import { Routes, Route } from 'react-router-dom';
import { adminRoutes, customerRoutes } from './RoutesConfig';
import { useAuth } from './AuthContext';
const ProtectedRoutes = () => {
  const { userRole } = useAuth();
  const routes = userRole === 'admin' ? adminRoutes : customerRoutes;

  return (
    <Routes>
      {routes.map(({ path, element }, index) => (
        <Route key={index} path={path} element={element} />
      ))}
    </Routes>
  );
};

export default ProtectedRoutes;
