import { useContext, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  useTheme,
} from '@mui/material';
import { tokens, ColorModeContext } from '../../theme';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ComputerIcon from '@mui/icons-material/Computer';
import MemoryIcon from '@mui/icons-material/Memory';
import DnsIcon from '@mui/icons-material/Dns';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import BuyApi from '../../routes/product/buyRoutes';
import { useNavigate } from 'react-router-dom';
import InvoiceAPi from '../../routes/product/invoiceRoutes';

const OptionSelect = ({ label, value, onChange, options, icon: Icon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ mt: 1, width: '100%' }}>
      <InputLabel
        id={`${label}-label`}
        htmlFor={`${label}-select`}
        sx={{
          color:
            theme.palette.mode === 'light'
              ? colors.grey[200]
              : colors.greenAccent[500],
          marginBottom: '4px',
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-label`}
        id={`${label}-select`}
        value={value}
        onChange={onChange}
        fullWidth
        sx={{
          color: colors.grey[100],
          '& .MuiSelect-icon': {
            color: colors.grey[500],
          },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.greenAccent[500],
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.greenAccent[500],
          },
          height: '40px', // Reduce height of the select component
        }}
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 200, backgroundColor: colors.primary[900] },
          },
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option.id}
            value={option.value}
            sx={{ color: colors.grey[100] }}
          >
            {Icon && <Icon sx={{ marginRight: '8px' }} />}
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

const BuySection = ({
  title,
  icon: Icon,
  options,
  handleBuy,
  pricing,
  children,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ mt: 4 }}>
      <Box
        display="flex"
        alignItems="center"
        mb={2}
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Icon
            sx={{
              color: colors.greenAccent[600],
              fontSize: '30px',
              marginRight: '8px',
            }}
          />
          <Typography variant="h4" color={colors.grey[100]} fontWeight="bold">
            {title}
          </Typography>
        </Box>
        {pricing && (
          <Box display="flex" alignItems="center">
            <CurrencyRupeeIcon
              sx={{
                color:
                  pricing === 'Not available'
                    ? colors.redAccent[600]
                    : colors.greenAccent[600],
                fontSize: '30px',
                marginRight: '8px',
                textShadow:
                  pricing === 'Not available'
                    ? `0 0 10px ${colors.redAccent[600]}`
                    : `0 0 10px ${colors.greenAccent[600]}`,
              }}
            />
            <Typography
              variant="h3"
              fontWeight="bold"
              sx={{
                color:
                  pricing === 'Not available'
                    ? colors.redAccent[600]
                    : colors.grey[100],
                textShadow:
                  pricing === 'Not available'
                    ? `0 0 10px ${colors.redAccent[600]}`
                    : `0 0 10px ${colors.greenAccent[600]}`,
              }}
            >
              {pricing}
            </Typography>
          </Box>
        )}
      </Box>
      {children}
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          variant="contained"
          onClick={handleBuy}
          sx={{
            backgroundColor: colors.greenAccent[700],
            color: colors.grey[100],
            '&:hover': {
              backgroundColor: colors.greenAccent[500],
              boxShadow: `0 0 10px ${colors.greenAccent[500]}`,
            },
          }}
        >
          <VpnKeyIcon sx={{ marginRight: '8px' }} />
          {`Buy ${title.split(' ')[1]}`}
        </Button>
      </Box>
    </Box>
  );
};

const BuyPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [vpsOptions, setVpsOptions] = useState([]);
  const [allVps, setAllVps] = useState([]);
  const [proxyOptions, setProxyOptions] = useState([]);
  const [allProxy, setAllProxy] = useState([]);
  const [uniquePorts, setUniquePorts] = useState([]);
  const [selectedVpsOption, setSelectedVpsOption] = useState('');
  const [selectedProxyOption, setSelectedProxyOption] = useState('');
  const [selectedOs, setSelectedOs] = useState('');
  const [selectedRam, setSelectedRam] = useState('');
  const [selectedPort, setSelectedPort] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [selectedVpsPrice, setSelectedVpsPrice] = useState('');
  const [selectedProxyPrice, setSelectedProxyPrice] = useState('');
  const navigate = useNavigate();

  const handleBuyVps = async () => {
    const invoice = await InvoiceAPi.createInvoice({
      amount: parseFloat(selectedVpsPrice),
    });
    navigate('/user/checkout', {
      state: {
        invoiceId: invoice.payload.id,
        productType: 'VPS',
        selectedOption: selectedVpsOption,
        selectedOs,
        selectedRam,
        pricing: selectedVpsPrice,
      },
    });
  };

  const handleBuyProxy = async () => {
    const invoice = await InvoiceAPi.createInvoice({
      amount: selectedProxyPrice,
    });
    navigate('/user/checkout', {
      state: {
        invoiceId: invoice.payload.id,
        productType: 'Proxy',
        selectedOption: selectedProxyOption,
        selectedRam,
        selectedPort,
        username,
        password,
        pricing: selectedProxyPrice,
      },
    });
  };

  const uniqueOptions = (options, key) => {
    const uniqueMap = new Map();
    options.forEach(item => {
      const combinedValue = `${item[key]} ${item.cores}`;
      if (!uniqueMap.has(combinedValue)) {
        uniqueMap.set(combinedValue, item);
      }
    });
    return [...uniqueMap.values()];
  };

  const uniqueSeriesOptions = options => {
    const seriesSet = new Set();
    return options.filter(option => {
      if (seriesSet.has(option.series)) {
        return false;
      } else {
        seriesSet.add(option.series);
        return true;
      }
    });
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const vpsResponse = await BuyApi.getVpsOptions();
        setAllVps(vpsResponse.payload);
        setVpsOptions(uniqueSeriesOptions(vpsResponse.payload));

        const proxyResponse = await BuyApi.getProxyOptions();
        setAllProxy(proxyResponse.payload);
        setProxyOptions(uniqueSeriesOptions(proxyResponse.payload));
        setUniquePorts([
          ...new Set(proxyResponse.payload.map(item => item.port)),
        ]);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const findPricing = (options, series, ram) => {
    if (!series || !ram) return 'Not available';

    // Trim whitespace from series and ram
    const trimmedSeries = series.trim();
    const trimmedRam = ram.trim();

    console.log('Options:', options);
    console.log('Trimmed series:', trimmedSeries);
    console.log('Trimmed ram:', trimmedRam);

    const option = options.find(
      opt =>
        opt.series.trim() === trimmedSeries && opt.ram.trim() === trimmedRam,
    );

    console.log('result :: ===>>>',option)

    return option ? option.pricing : 'Not available';
  };

  useEffect(() => {
    setSelectedVpsPrice(findPricing(allVps, selectedVpsOption, selectedRam));
  }, [selectedVpsOption, selectedRam, allVps]);

  useEffect(() => {
    setSelectedProxyPrice(
      findPricing(allProxy, selectedProxyOption, selectedRam),
    );
  }, [selectedProxyOption, selectedRam, allProxy]);

  return (
    <Box m="20px" maxWidth="600px">
      <BuySection
        title="Buy VPS"
        icon={ComputerIcon}
        options={vpsOptions}
        handleBuy={handleBuyVps}
        pricing={selectedVpsPrice}
      >
        <OptionSelect
          label="Select VPS Series"
          value={selectedVpsOption}
          onChange={e => setSelectedVpsOption(e.target.value)}
          options={uniqueSeriesOptions(allVps).map(option => ({
            id: option.id,
            value: option.series,
            label: option.series,
          }))}
        />
        <OptionSelect
          label="Operating System"
          value={selectedOs}
          onChange={e => setSelectedOs(e.target.value)}
          options={uniqueOptions(allVps, 'os').map(option => ({
            id: option.id,
            value: option.os,
            label: option.os,
            icon: DnsIcon,
          }))}
        />
        <OptionSelect
          label="RAM"
          value={selectedRam}
          onChange={e => setSelectedRam(e.target.value)}
          options={uniqueOptions(allVps, 'ram').map(option => ({
            id: option.id,
            value: option.ram,
            label: option.ram,
            icon: MemoryIcon,
          }))}
        />
      </BuySection>

      <BuySection
        title="Buy Proxy"
        icon={VpnKeyIcon}
        options={proxyOptions}
        handleBuy={handleBuyProxy}
        pricing={selectedProxyPrice}
      >
        <OptionSelect
          label="Select Proxy Series"
          value={selectedProxyOption}
          onChange={e => setSelectedProxyOption(e.target.value)}
          options={uniqueSeriesOptions(allProxy).map(option => ({
            id: option.id,
            value: option.series,
            label: option.series,
          }))}
        />
        <OptionSelect
          label="RAM"
          value={selectedRam}
          onChange={e => setSelectedRam(e.target.value)}
          options={uniqueOptions(allProxy, 'ram').map(option => ({
            id: option.id,
            value: option.ram,
            label: option.ram,
            icon: MemoryIcon,
          }))}
        />
        <OptionSelect
          label="Port"
          value={selectedPort}
          onChange={e => setSelectedPort(e.target.value)}
          options={uniquePorts.map(port => ({
            id: port,
            value: port,
            label: port,
          }))}
        />
        <Box sx={{ mt: 2 }}>
          <InputLabel
            htmlFor="username"
            sx={{
              color:
                theme.palette.mode === 'light'
                  ? colors.grey[200]
                  : colors.greenAccent[500],
              marginBottom: '4px',
            }}
          >
            Username
          </InputLabel>
          <TextField
            id="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
            fullWidth
            sx={{
              color: colors.grey[100],
              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.greenAccent[500],
              },
            }}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <InputLabel
            htmlFor="password"
            sx={{
              color:
                theme.palette.mode === 'light'
                  ? colors.grey[200]
                  : colors.greenAccent[500],
              marginBottom: '4px',
            }}
          >
            Password
          </InputLabel>
          <TextField
            id="password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            fullWidth
            sx={{
              color: colors.grey[100],
              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.greenAccent[500],
              },
            }}
          />
        </Box>
      </BuySection>
    </Box>
  );
};

export default BuyPage;
