import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getAuthToken = () => {
  return localStorage.getItem('token');
};

const InvoiceAPi = {
  getInvoices: async status => {
    const token = getAuthToken();
    const url = status
      ? `${API_URL}/invoice?status=${status}`
      : `${API_URL}/invoice`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  createInvoice: async invoiceData => {
    const token = getAuthToken();
    const response = await axios.post(`${API_URL}/invoice/`, invoiceData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  updateInvoiceCustomer: async (id, status, utrNumber) => {
    const token = getAuthToken();
    const response = await axios.patch(
      `${API_URL}/invoice/customer/${id}?status=${status}`,
      { utr: utrNumber },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  },

  updateInvoiceAdmin: async (id, status, data) => {
    try {
      const token = getAuthToken();
      const response = await axios.patch(
        `${API_URL}/invoice/admin/${id}?status=${status}`,
        { credentials: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error updating admin invoice:', error);
      throw error;
    }
  },

  getInvoice: async id => {
    const token = getAuthToken();
    const response = await axios.get(`${API_URL}/invoice/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  uploadInvoice: async (id, file) => {
    const token = getAuthToken();

    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post(
        `${API_URL}/invoice/upload/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error uploading invoice:', error);
      throw error;
    }
  },

  getImage: async id => {
    const token = getAuthToken();
    try {
      const response = await axios.get(`${API_URL}/invoice/image/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob', // Ensure the response is a blob
      });
      const imageUrl = URL.createObjectURL(response.data);
      return imageUrl;
    } catch (error) {
      console.error('Error uploading invoice:', error);
      throw error;
    }
  },

  getInvoicesType: async (status, type) => {
    const token = getAuthToken();
    const response = await axios.get(
      `${API_URL}/invoice?status=${status}&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  },
};

export default InvoiceAPi;
