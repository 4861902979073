import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  CssBaseline,
  InputAdornment,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokens, ColorModeContext } from '../../theme';
import LightModeIcon from '@mui/icons-material/LightMode';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link, useNavigate } from 'react-router-dom';
import AuthRoutes from '../../routes/auth/authRoutes';

const GlassBox = ({ children }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '10px',
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%', // Adjust width for better responsiveness
        maxWidth: '400px',
        margin: 'auto',
        position: 'relative',
        boxShadow: `0px 0px 10px 2px ${colors.greenAccent[500]}`, // Applying glow effect
        '&:hover': {
          boxShadow: `0px 0px 20px 4px ${colors.greenAccent[500]}`, // Stronger glow on hover
        },
        '@media (max-width: 600px)': {
          padding: theme.spacing(2),
        },
      }}
    >
      {children}
    </Box>
  );
};

const LoginPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/buy'); // Redirect to dashboard if already logged in
    }
  }, [navigate]);

  const handleLogin = async () => {
    try {
      const response = await AuthRoutes.login(email, password);
      AuthRoutes.setAuthToken(response.payload.token);
      navigate('/buy'); // Redirect to the dashboard after successful login
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Login failed. Please try again.');
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(!validateEmail(value));
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <>
      <CssBaseline />
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: theme.spacing(2), // Add padding for better responsiveness
        }}
      >
        <GlassBox>
          <Box display="flex" justifyContent="space-between" width="100%" mb="20px">
            <Typography variant="h4" component="h1" gutterBottom>
              Login
            </Typography>
            <IconButton
              sx={{ color: colors.grey[100] }}
              onClick={colorMode.toggleColorMode}
            >
              <LightModeIcon />
            </IconButton>
          </Box>
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            label="Email"
            value={email}
            onChange={handleEmailChange}
            error={emailError}
            helperText={emailError ? 'Please enter a valid email address' : ''}
            sx={{
              input: { color: colors.grey[100] },
              label: { color: colors.grey[300] },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: colors.grey[300] },
                '&:hover fieldset': { borderColor: colors.grey[100] },
                '&.Mui-focused fieldset': {
                  borderColor: colors.greenAccent[600],
                },
              },
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              input: { color: colors.grey[100] },
              label: { color: colors.grey[300] },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: colors.grey[300] },
                '&:hover fieldset': { borderColor: colors.grey[100] },
                '&.Mui-focused fieldset': {
                  borderColor: colors.greenAccent[600] },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ color: colors.grey[100] }}
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              backgroundColor: colors.greenAccent[700],
              color: colors.grey[100],
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: colors.greenAccent[800],
              },
            }}
            onClick={handleLogin}
            disabled={emailError} // Disable login button if email is invalid
          >
            Login
          </Button>
          <Typography variant="body2" color={colors.grey[300]} mt="10px">
            Don't have an account?{' '}
            <Link to="/signup" style={{ color: colors.greenAccent[500] }}>
              Sign Up
            </Link>
          </Typography>
        </GlassBox>
      </Container>
    </>
  );
};

export default LoginPage;
