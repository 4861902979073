import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Avatar,
  IconButton,
  TextField,
  Modal,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import InvoiceRoutes from '../../routes/transaction/invoiceRoutes';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import EditIcon from '@mui/icons-material/Edit';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import MemoryIcon from '@mui/icons-material/Memory';
import PublicIcon from '@mui/icons-material/Public';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';

const UserPendingInvoices = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [fullScreenImageOpen, setFullScreenImageOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [imageError, setImageError] = useState(false);

  const [formData, setFormData] = useState({
    ip: '',
    username: '',
    password: '',
  });

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await InvoiceRoutes.getInvoices('paid');
        setInvoices(response.payload.invoices);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    fetchInvoices();
  }, []);

  const handleEditClick = async id => {
    const invoice = invoices.find(inv => inv.id === id);
    if (invoice) {
      setSelectedInvoice(invoice);
      setFormData({
        ip: invoice.ip || '',
        username: invoice.username || '',
        password: invoice.password || '',
      });
      setOpen(true);
      setLoadingImage(true);
      setImageLoaded(false);
      setImageError(false);
      try {
        const imageResponse = await InvoiceRoutes.getImage(id);
        setImageSrc(imageResponse);
        setImageLoaded(true);
      } catch (error) {
        console.error('Error fetching invoice image:', error);
        setImageSrc(null);
        setImageLoaded(true);
        setImageError(true);
      } finally {
        setLoadingImage(false);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedInvoice(null);
    setImageSrc(null);
    setFormData({
      ip: '',
      username: '',
      password: '',
    });
    setZoomLevel(1);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async () => {
    // Log the updated data to the console
    console.log('Updated Data:', {
      ...selectedInvoice,
      ...formData,
    });

    // Create an object with the form data to be sent to the API
    const creds = {
      ip: formData.ip,
      username: formData.username,
      password: formData.password,
    };

    try {
      // Make the API request to update the invoice
      await InvoiceRoutes.updateInvoiceAdmin(
        selectedInvoice.id,
        'approved',
        creds,
      );
      // Close the dialog after successful update
      handleClose();
    } catch (error) {
      // Log any error that occurs during the API request
      console.error('Error updating invoice:', error);
    }
  };

  const openFullScreenImage = () => {
    setFullScreenImageOpen(true);
    setZoomLevel(1);
  };

  const closeFullScreenImage = () => {
    setFullScreenImageOpen(false);
    setZoomLevel(1);
  };

  const zoomIn = () => {
    setZoomLevel(prev => Math.min(prev * 1.2, 3));
  };

  const zoomOut = () => {
    setZoomLevel(prev => Math.max(prev / 1.2, 1));
  };

  const columns = [
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      renderCell: params => (
        <Box display="flex" alignItems="center">
          <CurrencyRupeeIcon sx={{ mr: 1, color: colors.greenAccent[500] }} />
          <Typography>{params.row.amount}</Typography>
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: params => (
        <Box display="flex" alignItems="center">
          {params.row.status === 'paid' ? (
            <CheckCircleIcon sx={{ mr: 1, color: colors.greenAccent[500] }} />
          ) : (
            <PendingActionsIcon sx={{ mr: 1, color: colors.orange[500] }} />
          )}
          <Typography>{params.row.status}</Typography>
        </Box>
      ),
    },
    {
      field: 'generatedAt',
      headerName: 'Generated At',
      flex: 1,
      renderCell: params => (
        <Box display="flex" alignItems="center">
          <CalendarTodayIcon sx={{ mr: 1, color: colors.greenAccent[500] }} />
          <Typography>{params.row.generatedAt}</Typography>
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: params => (
        <IconButton
          color="primary"
          onClick={() => handleEditClick(params.row.id)}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="INVOICES" subtitle="List of Invoice Balances" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={invoices}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 50]}
          getRowId={row => row.id}
        />
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '10px',
            boxShadow: `0px 0px 10px 2px ${colors.greenAccent[500]}`,
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
            minWidth: '800px',
            minHeight: '600px',
          },
        }}
      >
        <DialogTitle>
          <Typography
            variant="h5"
            component="div"
            sx={{ color: theme.palette.text.primary }}
          >
            Invoice Details
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          {selectedInvoice && (
            <Box display="flex" flexDirection="column" gap={3}>
              {/* Amount */}
              <Box display="flex" alignItems="center">
                <Avatar sx={{ bgcolor: colors.greenAccent[500], mr: 2 }}>
                  <CurrencyRupeeIcon />
                </Avatar>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.primary }}
                >
                  <strong>Amount:</strong> {selectedInvoice.amount}
                </Typography>
              </Box>

              {selectedInvoice.utr && (
                <Box display="flex" alignItems="center">
                  <Avatar sx={{ bgcolor: colors.greenAccent[500], mr: 2 }}>
                    <CheckCircleIcon />
                  </Avatar>
                  <Typography
                    variant="body1"
                    sx={{ color: theme.palette.text.primary }}
                  >
                    <strong>UTR:</strong> {selectedInvoice.utr}
                  </Typography>
                </Box>
              )}

              {/* Product Details */}
              {selectedInvoice.productDetails && (
                <>
                  {selectedInvoice.productDetails.os && (
                    <Box display="flex" alignItems="center">
                      <Avatar sx={{ bgcolor: colors.greenAccent[500], mr: 2 }}>
                        <DesktopWindowsIcon />
                      </Avatar>
                      <Typography
                        variant="body1"
                        sx={{ color: theme.palette.text.primary }}
                      >
                        <strong>OS:</strong> {selectedInvoice.productDetails.os}
                      </Typography>
                    </Box>
                  )}
                  {selectedInvoice.productDetails.product && (
                    <Box display="flex" alignItems="center">
                      <Avatar sx={{ bgcolor: colors.greenAccent[500], mr: 2 }}>
                        <LaptopMacIcon />
                      </Avatar>
                      <Typography
                        variant="body1"
                        sx={{ color: theme.palette.text.primary }}
                      >
                        <strong>Product:</strong>{' '}
                        {selectedInvoice.productDetails.product}
                      </Typography>
                    </Box>
                  )}
                  {selectedInvoice.productDetails.ram && (
                    <Box display="flex" alignItems="center">
                      <Avatar sx={{ bgcolor: colors.greenAccent[500], mr: 2 }}>
                        <MemoryIcon />
                      </Avatar>
                      <Typography
                        variant="body1"
                        sx={{ color: theme.palette.text.primary }}
                      >
                        <strong>RAM:</strong>{' '}
                        {selectedInvoice.productDetails.ram}
                      </Typography>
                    </Box>
                  )}
                  {selectedInvoice.productDetails.ipSeries && (
                    <Box display="flex" alignItems="center">
                      <Avatar sx={{ bgcolor: colors.greenAccent[500], mr: 2 }}>
                        <PublicIcon />
                      </Avatar>
                      <Typography
                        variant="body1"
                        sx={{ color: theme.palette.text.primary }}
                      >
                        <strong>IP Series:</strong>{' '}
                        {selectedInvoice.productDetails.ipSeries}
                      </Typography>
                    </Box>
                  )}
                </>
              )}

              {/* Image Preview */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                {loadingImage && !imageLoaded && <CircularProgress />}
                {imageLoaded && imageSrc && !imageError && (
                  <Box>
                    <img
                      src={imageSrc}
                      alt="Invoice"
                      style={{ width: '100%', cursor: 'pointer' }}
                      onClick={openFullScreenImage}
                    />
                  </Box>
                )}
                {imageError && (
                  <Typography variant="body1" color="error">
                    Error loading image. Please try again later.
                  </Typography>
                )}
              </Box>

              {/* Form */}
              <Box mt={2}>
                <TextField
                  label="IP"
                  name="ip"
                  value={formData.ip}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Username"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFormSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Full-Screen Image Modal */}
      <Modal
        open={fullScreenImageOpen}
        onClose={closeFullScreenImage}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '90%',
            height: '90%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'black',
            overflow: 'hidden',
          }}
        >
          <IconButton
            onClick={zoomOut}
            sx={{
              position: 'absolute',
              top: 10,
              left: 10,
              color: 'white',
            }}
          >
            <ZoomOutIcon />
          </IconButton>
          <IconButton
            onClick={zoomIn}
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              color: 'white',
            }}
          >
            <ZoomInIcon />
          </IconButton>
          <img
            src={imageSrc}
            alt="Full Screen"
            style={{
              width: `${zoomLevel * 100}%`,
              height: `${zoomLevel * 100}%`,
              objectFit: 'contain',
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default UserPendingInvoices;
