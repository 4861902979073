import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Avatar,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import InvoiceRoutes from '../../routes/transaction/invoiceRoutes'; // Import the service
import EventIcon from '@mui/icons-material/Event';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import PublicIcon from '@mui/icons-material/Public';

const MyProxyPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await InvoiceRoutes.getInvoicesType('approved', 2); // Fetch approved invoices
        setInvoices(response.payload.invoices);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    fetchInvoices();
  }, []);

  const handleRowClick = async params => {
    try {
      const response = await InvoiceRoutes.getInvoice(params.row.id);
      setSelectedInvoice(response.payload);
      setOpen(true);
    } catch (error) {
      console.error('Error fetching invoice details:', error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedInvoice(null);
  };

  const columns = [
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      renderCell: params => (
        <Box display="flex" alignItems="center">
          <CurrencyRupeeIcon sx={{ mr: 1, color: colors.greenAccent[500] }} />
          <Typography>{params.row.amount}</Typography>
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: params => (
        <Box display="flex" alignItems="center">
          {params.row.status === 'pending' && (
            <PendingActionsIcon
              sx={{ mr: 1, color: colors.greenAccent[500] }}
            />
          )}
          {params.row.status === 'completed' && (
            <CheckCircleIcon sx={{ mr: 1, color: colors.greenAccent[500] }} />
          )}
          {params.row.status === 'paid' && (
            <AssignmentTurnedInIcon
              sx={{ mr: 1, color: colors.greenAccent[500] }}
            />
          )}
          <Typography>{params.row.status}</Typography>
        </Box>
      ),
    },
    {
      field: 'generatedAt',
      headerName: 'Paid At',
      flex: 1,
      renderCell: params => (
        <Box display="flex" alignItems="center">
          <CalendarTodayIcon sx={{ mr: 1, color: colors.greenAccent[500] }} />
          <Typography>{params.row.generatedAt}</Typography>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="MY PROXY" subtitle="List of Proxies" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={invoices}
          columns={columns}
          onRowClick={handleRowClick}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: '10px',
            boxShadow: `0px 0px 10px 2px ${colors.greenAccent[500]}`,
            padding: theme.spacing(4),
            backgroundColor: theme.palette.background.default,
            '&:hover': {
              boxShadow: `0px 0px 20px 4px ${colors.greenAccent[500]}`,
            },
          },
        }}
      >
        <DialogTitle>
          <Typography
            variant="h5"
            component="div"
            sx={{ color: theme.palette.text.primary }}
          >
            Credentials
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          {selectedInvoice && (
            <Box display="flex" flexDirection="column" gap={2}>
              {selectedInvoice.credentials && (
                <>
                  <Box display="flex" alignItems="center">
                    <Avatar sx={{ bgcolor: colors.greenAccent[500], mr: 2 }}>
                      <AccountCircleIcon />
                    </Avatar>
                    <Typography
                      variant="body1"
                      sx={{ color: theme.palette.text.primary }}
                    >
                      <strong>Username:</strong> {selectedInvoice.credentials.username}
                    </Typography>
                  </Box>
                  <Divider sx={{ backgroundColor: theme.palette.divider }} />
                  <Box display="flex" alignItems="center">
                    <Avatar sx={{ bgcolor: colors.greenAccent[500], mr: 2 }}>
                      <LockIcon />
                    </Avatar>
                    <Typography
                      variant="body1"
                      sx={{ color: theme.palette.text.primary }}
                    >
                      <strong>Password:</strong> {selectedInvoice.credentials.password}
                    </Typography>
                  </Box>
                  <Divider sx={{ backgroundColor: theme.palette.divider }} />
                  <Box display="flex" alignItems="center">
                    <Avatar sx={{ bgcolor: colors.greenAccent[500], mr: 2 }}>
                      <PublicIcon />
                    </Avatar>
                    <Typography
                      variant="body1"
                      sx={{ color: theme.palette.text.primary }}
                    >
                      <strong>IP Address:</strong> {selectedInvoice.credentials.ip}
                    </Typography>
                  </Box>
                </>
              )}
              {/* Add more fields as needed */}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: colors.greenAccent[500] }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MyProxyPage;
